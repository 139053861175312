import React from 'react';

const StarRating = ({ rating }) => {
  const fullStar = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 30 30" fill="none">
      <path d="M15.0001 20.7681L10.3751 24.2993C10.146 24.4868 9.89597 24.5751 9.62514 24.5643C9.35431 24.5543 9.11472 24.4764 8.90639 24.3306C8.69806 24.1847 8.53681 23.9868 8.42264 23.7368C8.30764 23.4868 8.30222 23.216 8.40639 22.9243L10.1876 17.1431L5.65639 13.9243C5.40639 13.7576 5.25014 13.5389 5.18764 13.2681C5.12514 12.9972 5.13556 12.7472 5.21889 12.5181C5.30222 12.2889 5.44806 12.0856 5.65639 11.9081C5.86472 11.7314 6.11472 11.6431 6.40639 11.6431H12.0001L13.8126 5.64307C13.9168 5.3514 14.0785 5.12723 14.2976 4.97057C14.516 4.81473 14.7501 4.73682 15.0001 4.73682C15.2501 4.73682 15.4843 4.81473 15.7026 4.97057C15.9218 5.12723 16.0835 5.3514 16.1876 5.64307L18.0001 11.6431H23.5939C23.8856 11.6431 24.1356 11.7314 24.3439 11.9081C24.5522 12.0856 24.6981 12.2889 24.7814 12.5181C24.8647 12.7472 24.8751 12.9972 24.8126 13.2681C24.7501 13.5389 24.5939 13.7576 24.3439 13.9243L19.8126 17.1431L21.5939 22.9243C21.6981 23.216 21.6931 23.4868 21.5789 23.7368C21.4639 23.9868 21.3022 24.1847 21.0939 24.3306C20.8856 24.4764 20.646 24.5543 20.3751 24.5643C20.1043 24.5751 19.8543 24.4868 19.6251 24.2993L15.0001 20.7681Z" fill="#FFA617" />
    </svg>
  );

  const halfStar = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 30 30" fill="none">
      <path d="M14.9999 10.1562V19.9062L18.9374 22.3125L17.9062 17.8125L21.3749 14.8125L16.8124 14.4062L14.9999 10.1562ZM14.9999 22.8437L9.81242 25.9687C9.58326 26.1146 9.34367 26.1771 9.09367 26.1562C8.84367 26.1354 8.62492 26.0521 8.43742 25.9062C8.24992 25.7604 8.10409 25.5779 7.99992 25.3587C7.89576 25.1396 7.87492 24.895 7.93742 24.625L9.31242 18.7187L4.71867 14.75C4.51034 14.5625 4.38034 14.3487 4.32867 14.1087C4.27701 13.8687 4.29242 13.6346 4.37492 13.4062C4.45826 13.1771 4.58326 12.9896 4.74992 12.8437C4.91659 12.6979 5.14576 12.6042 5.43742 12.5625L11.4999 12.0312L13.8437 6.46875C13.9478 6.21875 14.1095 6.03125 14.3287 5.90625C14.5478 5.78125 14.7716 5.71875 14.9999 5.71875C15.2291 5.71875 15.4528 5.78125 15.6712 5.90625C15.8895 6.03125 16.0512 6.21875 16.1562 6.46875L18.4999 12.0312L24.5624 12.5625C24.8541 12.6042 25.0833 12.6979 25.2499 12.8437C25.4166 12.9896 25.5416 13.1771 25.6249 13.4062C25.7083 13.6354 25.7241 13.87 25.6724 14.11C25.6208 14.35 25.4903 14.5633 25.2812 14.75L20.6874 18.7187L22.0624 24.625C22.1249 24.8958 22.1041 25.1408 21.9999 25.36C21.8958 25.5792 21.7499 25.7612 21.5624 25.9062C21.3749 26.0521 21.1562 26.1354 20.9062 26.1562C20.6562 26.1771 20.4166 26.1146 20.1874 25.9687L14.9999 22.8437Z" fill="#FFA617" />
    </svg>
  );

  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - (fullStars + (hasHalfStar ? 1 : 0));

    for (let i = 0; i < fullStars; i++) {
      stars.push(fullStar);
    }
    if (hasHalfStar) {
      stars.push(halfStar);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 30 30" fill="none">
          <path d="M24.5624 11.3L18.5124 10.775L16.1499 5.2125C15.7249 4.2 14.2749 4.2 13.8499 5.2125L11.4874 10.7875L5.44991 11.3C4.34991 11.3875 3.89991 12.7625 4.73741 13.4875L9.32491 17.4625L7.94991 23.3625C7.69991 24.4375 8.86241 25.2875 9.81241 24.7125L14.9999 21.5875L20.1874 24.725C21.1374 25.3 22.2999 24.45 22.0499 23.375L20.6749 17.4625L25.2624 13.4875C26.0999 12.7625 25.6624 11.3875 24.5624 11.3ZM14.9999 19.25L10.2999 22.0875L11.5499 16.7375L7.39991 13.1375L12.8749 12.6625L14.9999 7.625L17.1374 12.675L22.6124 13.15L18.4624 16.75L19.7124 22.1L14.9999 19.25Z" fill="#FFA617" />
        </svg>
      );
    }

    return stars.map((star, index) => (
      <span key={index} className="star-icon">
        {star}
      </span>
    ));
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;
