import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// import "../styles/_client_testimonies_slider.scss";
import LeftArrowIcon from "./leftArrowIcon";
import RightArrowIcon from "./rightArrowIcon";
import ClientImage1 from "../images/qa-service/client1.png";
import ClientImage2 from "../images/qa-service/client2.jpeg";
import ClientImage3 from "../images/qa-service/client3.jpg";
import StarRating from "./StarRating";

const clientTestimonials = [
    {
        id: 1,
        clientName: "Jose Tamayo",
        position: "QA Director, Tunecore",
        testimony:
            "Sedin’s QA team excelled in managing requirements and scope changes. They were flexible, professional, and seamlessly integrated with our team culturally.",
        rating: 4.5,
        image: ClientImage1,
    },
    {
        id: 2,
        clientName: "Waleed Ather",
        position: "Test Automation Architect, Rewaa",
        testimony:
            "Sedin QA team's provided exceptional service to us & undoubtedly boast a roster of highly skilled talent, making them a trusted partner in our success.",
        image: ClientImage2,
        rating: 4,
    },
    {
        id: 3,
        clientName: "John McCarthy",
        position: "CEO, Pricebook Digital",
        testimony:
            "Sedin has been instrumental in our success. Despite time zone differences, they are always accessible and accommodate our schedules seamlessly.",
        rating: 4.6,
        image: ClientImage3,
    },
];

const ClientTestimoniesSlider = () => {
    return (
        <>
            <div className="pagination-navigation">
                <div className="pagination-arrows">
                    <span className="prev-openings">
                        <LeftArrowIcon className="left-arrow-icon" />
                    </span>
                </div>
            </div>
            <div className="client-testimonies-slider">
                <Swiper
                    modules={[Pagination, Navigation]}
                    spaceBetween={10}
                    navigation={{
                        nextEl: ".next-openings",
                        prevEl: ".prev-openings",
                    }}
                    pagination={{
                        el: ".openings-pagination",
                        clickable: true,
                    }}
                    slidesPerView={1}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                        1024: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {clientTestimonials.map((testimonial) => (
                        <SwiperSlide key={testimonial.id}>
                            <div className="testimonial-item-qa">
                                <div className="testimonial-image">
                                    <img
                                        src={testimonial.image}
                                        alt="Client"
                                        className="testimonial-image__tag"
                                    />
                                </div>
                                <div className="testimonial-content">
                                    <div className="client-name-star-container">
                                        <p className="client-name">{testimonial.clientName}</p>
                                        <StarRating rating={testimonial.rating} />
                                    </div>
                                    <p className="position">{testimonial.position}</p>
                                    <p className="testimony">{testimonial.testimony}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="pagination-navigation">
                <div className="pagination-arrows">
                    <span className="next-openings">
                        <RightArrowIcon className="right-arrow-icon" />
                    </span>
                </div>
            </div>
        </>
    );
};

export default ClientTestimoniesSlider;
