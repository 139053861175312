import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import ArrowIcon from "../components/ArrowIcon";
// import "../styles/_case_studies_slider.scss";
import Image1 from "../images/qa-service/tunecore.svg";
import Image2 from "../images/qa-service/rewa.svg";
import Image3 from "../images/qa-service/abridge_logo.png";
import bulletPoints from "../images/qa-service/bullet-points.svg";
import leftArrow from "../images/qa-service/left.svg";
import rightArrow from "../images/qa-service/rightr.svg";

const caseStudies = [
    {
        id: 1,
        Description: {
            Description:
                "We helped our client, Tunecore- a global digital music distribution giant, reengineer its product credibility significantly. Our collaboration resulted in improved product quality, streamlined operations, and significant cost savings, reinforcing their commitment to excellence.",
        },
        image: Image1,
        bulletPoints: [
            "Automated 7 Domains with 15+ Modules to 300+ Test cases",
            "Achieved 90% API Automation & 80% UI Automation",
            "Saved $400k in Cost Through Test Automation",
        ],
    },
    {
        id: 2,
        Description: {
            Description:
                "We collaborated with Rewaa, a leading provider of technical solutions in the Middle East, to optimize their efficiency, reduce time and costs associated with manual testing. This approach accelerated release cycles and improved application performance significantly.",
        },
        image: Image2,
        bulletPoints: [
            "Achieved 80% Coverage in End-to-End UI Testing",
            "Witnessed 97% Defect Removal Efficiency (DRE)",
            "Saved $2K PM in Regression Testing Costs",
        ],
    },
    {
        id: 3,
        Description: {
            Description:
                "We joined hands with Abridge, a rapidly emerging leader in healthcare technology, to develop a scalable, resilient, and maintainable test automation framework to minimize risks associated with regression bugs and glitches while optimizing release times.",
        },
        image: Image3,
        bulletPoints: [
            "95% of Test Automated as per Industry Standard",
            "Reduced Testing Efforts from 8 Hours per Week to 15 Mins per Week",
            "Every Release is 100% Regression Tested & 80% Lesser Test Schedule",
        ],
    },
];

const SwiperCaseComponent = () => {
    return (
        <>
            <div className="case-slider-container">
                <Swiper
                    modules={[Pagination, Navigation]}
                    spaceBetween={10}
                    navigation={{
                        nextEl: ".next-openings",
                        prevEl: ".prev-openings",
                    }}
                    pagination={{
                        el: ".openings-pagination",
                        clickable: true,
                    }}
                    slidesPerView={1}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                        1024: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {caseStudies.map((caseStudy) => (
                        <SwiperSlide key={caseStudy.id}>
                            <div
                                className="openings-item"
                                style={{ textAlign: "center", padding: "10px" }}
                            >
                                <p className="description">{caseStudy.Description.Description}</p>
                                <ul className="custom-bullets">
                                    {caseStudy.bulletPoints.map((point, index) => (
                                        <li className="list-case-study" key={index}>
                                            <div className="icon">
                                                <img src={bulletPoints} alt="" />
                                            </div>
                                            {point}
                                        </li>
                                    ))}
                                </ul>
                                <div className="case-study-image">
                                    <img
                                        src={caseStudy.image}
                                        alt="CaseImage"
                                        className="case-study-image__tag"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="pagination-navigation">
                <div className="openings-arrows">
                    <span className="prev-openings">
                        <div className="left-arrow">
                            <img src={leftArrow} />
                        </div>
                    </span>
                    <div className="openings-pagination"></div>
                    <span className="next-openings">
                        <div className="right-arrow">
                            <img src={rightArrow} />
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
};

export default SwiperCaseComponent;
