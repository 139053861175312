import React from 'react';

const leftIcon = ({ fillColor = "#FFA617" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      d="M64 32C64 27.7977 63.1723 23.6365 61.5641 19.7541C59.956 15.8717 57.5989 12.3441 54.6274 9.37258C51.6559 6.40111 48.1283 4.044 44.2459 2.43585C40.3635 0.827702 36.2023 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 36.2023 0.827702 40.3635 2.43585 44.2459C4.044 48.1283 6.40111 51.6559 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32ZM38.4 48L22.4 32L38.4 16V48Z"
      fill={fillColor}
    />
  </svg>
);

export default leftIcon;
